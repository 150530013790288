import React, { Fragment } from 'react';
import { Link, withPrefix } from 'gatsby';
import Helmet from 'react-helmet';
import favicon from '../../static/icon-32x32.png';
import { Menu } from './menu';
import { TwitterCardHelmet } from './twitter-card-helmet';
import { SocialIcon } from 'react-social-icons';
import meta from '../theme/meta';
import { palette } from '../theme/palette';
import { Logo, Stack } from './components';

export const Header = ({ title }) => {
    const pageTitle = meta.title + `${title ? ': ' + title : ''}`;

    return (
        <Fragment>
            <Helmet
                title={pageTitle}
                meta={[
                    { name: 'charset', content: 'utf-8' },
                    { name: 'description', content: meta.description },
                    {
                        name: 'keywords',
                        content: meta.keywords.join(','),
                    },
                ]}
            >
                <link rel="icon" type="image/png" href={favicon} />
            </Helmet>

            <TwitterCardHelmet title={pageTitle} description={meta.description} image={withPrefix('/icon-64x64.png')} />

            <Stack type={'column'} align={'stretch'} style={{ marginBottom: '1rem' }}>
                <Link
                    to="/"
                    style={{
                        textDecoration: 'none',
                    }}
                >
                    <Stack>
                        <Logo />
                        <h1 style={{ margin: 0, marginLeft: '0.5rem', display: 'inline-block' }}>{meta.title}</h1>
                    </Stack>
                </Link>
                <h4>{meta.description}</h4>
                <Stack type={'row'} justify={'flex-end'} style={{ marginTop: '1rem' }}>
                    <SocialIcon
                        url={meta.author.twitter.url}
                        style={{ width: 32, height: 32 }}
                        title={`Follow @${meta.author.twitter.handle}`}
                    />

                    <SocialIcon
                        url={meta.author.github.url}
                        color={palette.dark}
                        style={{ width: 32, height: 32 }}
                        title={`Check out ${meta.author.github.handle}`}
                    />
                </Stack>
            </Stack>

            <Menu />
        </Fragment>
    );
};
