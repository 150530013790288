import styled from 'styled-components';
import { typography } from '../theme/typography';
import { palette } from '../theme/palette';
import { Link } from 'gatsby';
import React from 'react';
import { Stack } from './components';

const MenuContainer = styled(Stack).attrs({
    type: 'row',
    gap: 0,
    textColor: props => props.textColor || palette.primary,
    highlightColor: props => props.highlightColor || palette.lightGray,
})`
    margin-bottom: 2rem;
    text-align: center;
    font-family: ${typography.options.headerFontFamily.join(',')};
    font-size: 0.75rem;

    a {
        display: inline-block;
        padding: 0.25rem 1rem;
        margin-bottom: 0.5rem;
        text-decoration: none;
        color: ${props => props.textColor};
        border-bottom: ${props => (props.top ? '5px solid transparent' : 'transparent')};
        border-top: ${props => (props.top ? 'transparent' : '5px solid transparent')};
    }

    a:hover {
        border-bottom-color: ${props => (props.top ? props.highlightColor : 'transparent')};
        border-top-color: ${props => (props.top ? 'transparent' : props.highlightColor)};
    }

    a.active {
        border-bottom-color: ${props => (props.top ? props.textColor : 'transparent')};
        border-top-color: ${props => (props.top ? 'transparent' : props.textColor)};
    }
`;

export const Menu = ({ isTop = true, textColor, highlightColor }) => (
    <MenuContainer top={isTop} justify={'center'} textColor={textColor} highlightColor={highlightColor}>
        <Link to={'/'} activeClassName={'active'}>
            Home
        </Link>
        <Link to={'/archive'} activeClassName={'active'}>
            Archive
        </Link>
        <Link to={'/about'} activeClassName={'active'}>
            About
        </Link>
    </MenuContainer>
);
