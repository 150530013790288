import React, { Fragment } from 'react';
import { typography } from './typography';

export const TypographyDetails = () => {
    const { headerFontFamily, bodyFontFamily } = typography.options;
    return (
        <Fragment>
            <div style={{ fontFamily: headerFontFamily.join(',') }}>
                <small>Header Font Family:</small> {headerFontFamily.join(', ')}
            </div>
            <div style={{ fontFamily: bodyFontFamily.join(',') }}>
                <small>Body Font Family:</small> {bodyFontFamily.join(', ')}
            </div>
        </Fragment>
    );
};
