import styled from 'styled-components';

export const DemoFrame = styled.iframe`
    width: 100%;
    border: none;
    box-shadow: 1px 1px 3px #ccc;
    padding: 0.5rem;

    height: ${props => `${props.height | 400}px`};
`;
