import React, { Fragment } from 'react';
import styled from 'styled-components';
import { palette } from '../theme/palette';
import { Menu } from './menu';
import { Stack } from './components';
import { SocialIcon } from 'react-social-icons';
import meta from '../theme/meta';

const FooterContainer = styled(Stack).attrs({
    type: 'column',
    justify: 'center',
    align: 'center',
})`
    margin: 2rem 0;

    border-top: 1px solid ${palette.lightGray};
    padding: 1rem 0;
    color: ${palette.gray};
    background: ${palette.dark};
`;

const ExternalLink = styled.a.attrs({
    target: '_blank',
})`
    color: inherit;
`;

export const Footer = () => {
    return (
        <FooterContainer>
            <Menu isTop={false} textColor={palette.bright} highlightColor={palette.gray} />

            <Stack type={'row'} justify={'flex-end'} style={{ marginBottom: '0.5rem' }}>
                <SocialIcon
                    url={meta.author.twitter.url}
                    style={{ width: 32, height: 32 }}
                    title={`Follow @${meta.author.twitter.handle}`}
                />

                <SocialIcon
                    url={meta.author.github.url}
                    color={palette.bright}
                    style={{ width: 32, height: 32 }}
                    title={`Check out ${meta.author.github.handle}`}
                />

                {process.env.NODE_ENV === 'development' ? (
                    <Fragment>
                        <ExternalLink href={'/theme'}>Theme</ExternalLink>
                        <ExternalLink href={'https://app.netlify.com/sites/pixelingene/overview'}>
                            Netlify
                        </ExternalLink>
                        <ExternalLink href={'https://github.com/pavanpodila/ui-blog'}>
                            Github
                        </ExternalLink>
                    </Fragment>
                ) : null}
            </Stack>

            <small>
                Copyright &copy; 2006-
                {new Date().getFullYear()} PIXELINGENE
            </small>
        </FooterContainer>
    );
};
