import styled from 'styled-components';
import { palette } from '../theme/palette';
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants';

export const Aside = styled.div.attrs({
    align: props => props.align || 'left',
})`
    float: ${props => props.align};
    text-align: left;

    border-radius: 4px;
    border: 2px solid ${palette.gray};
    background: ${palette.bright};
    padding: 1rem;
    position: relative;
    font-size: 0.8rem;
    max-width: 40%;
    margin: ${props => (props.align === 'left' ? '1rem 1rem 1rem 0' : '1rem 0 1rem 1rem')};
    color: ${palette.primary};

    ${MOBILE_MEDIA_QUERY} {
        max-width: 100%;
        margin: 1rem 0;
        font-size: 0.9rem;
    }

    word-wrap: break-word;
`;
