import React from 'react';

export class Silverlight extends React.Component {
    render() {
        const { src, width, height } = this.props;

        return (
            <span
                style={{
                    display: 'block',
                    width: `${width}px`,
                    height: `${height}px`,
                }}
            >
                <object
                    width={`${width}px`}
                    height={`${height}px`}
                    type="application/x-silverlight-2"
                    data="data:application/x-silverlight-2,"
                >
                    <param name="source" value={src} />
                    <param name="onerror" value="onSilverlightError" />
                    <param name="background" value="white" />
                    <param name="minRuntimeVersion" value="4.0.60310.0" />
                    <param name="autoUpgrade" value="true" />
                    <a
                        href="http://go.microsoft.com/fwlink/?LinkID=149156&amp;v=4.0.60310.0"
                        style={{ textDecoration: 'none' }}
                    >
                        <img
                            src="http://go.microsoft.com/fwlink/?LinkId=161376"
                            alt="Get Microsoft Silverlight"
                            style={{ borderStyle: 'none' }}
                        />
                    </a>
                </object>
                <iframe
                    title={`Silverlight Embed - ${src}`}
                    style={{
                        visibility: 'hidden',
                        height: 0,
                        width: 0,
                        border: 0,
                    }}
                />
            </span>
        );
    }
}
