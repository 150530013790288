import React from 'react';
import { Post } from '../components/post';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { TwitterCardHelmet } from '../components/twitter-card-helmet';

export default ({ data, pageContext }) => (
    <Layout title={data.markdownRemark.frontmatter.title}>
        <TwitterCardHelmet description={data.markdownRemark.excerpt} />

        <Post data={data} context={pageContext} />
    </Layout>
);

export const query = graphql`
    query BlogPostQuery($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            htmlAst
            timeToRead
            excerpt(pruneLength: 140)
            frontmatter {
                title
                date(formatString: "MMMM Do, YYYY")
            }
        }
    }
`;
