import styled from 'styled-components';
import { Link } from 'gatsby';
import { palette } from '../theme/palette';
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants';
import React from 'react';

export const NavLink = styled(Link)`
    display: flex;
    align-items: center;

    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    text-decoration: none;
    flex: 1;

    background: ${palette.lightGray};
    color: ${palette.primary};

    justify-content: ${props => {
        if (props.align === 'left') {
            return 'flex-start';
        }
        if (props.align === 'right') {
            return 'flex-end';
        }
        if (props.align === 'center') {
            return 'center';
        }
    }};

    &:last-child {
        margin-right: 0;
    }

    ${MOBILE_MEDIA_QUERY} {
        margin-right: 0;
    }

    &:hover {
        background: ${palette.primary};
        color: ${palette.bright};
    }
`;

export const NavEnd = styled.div`
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    flex: 1;

    background: ${palette.lightGray};
    color: ${palette.gray};

    text-align: ${props => props.align || 'left'};

    &:last-child {
        margin-right: 0;
    }

    ${MOBILE_MEDIA_QUERY} {
        margin-right: 0;
    }
`;

export const NavLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin: 2rem 0;

    ${MOBILE_MEDIA_QUERY} {
        flex-direction: column;
    }
`;

export const LeftArrow = () => <span style={{ display: 'inline-block', marginRight: '0.5rem' }}>&lArr;</span>;
export const RightArrow = () => <span style={{ display: 'inline-block', marginLeft: '0.5rem' }}>&rArr;</span>;
