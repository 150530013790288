import React from 'react';

export class JSFiddle extends React.Component {
    static defaultProps = {
        height: 400,
    };

    render() {
        const { id, height } = this.props;

        return (
            <iframe
                title={`JSFiddle Embed - ${id}`}
                width="100%"
                height={height}
                src={`//jsfiddle.net/${id}/embedded/js,result/`}
                allowFullScreen
                frameBorder="0"
            />
        );
    }
}
