import { GlobalStyle } from '../theme/global-style';

import React, { Fragment } from 'react';

import { Header } from './header';
import { graphql, StaticQuery } from 'gatsby';
import { Footer } from './footer';

const query = graphql`
    query SiteMetaQuery {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;

const PageLayout = ({
    title,
    data: {
        site: { siteMetadata },
    },
    children,
}) => {
    return (
        <Fragment>
            <GlobalStyle />
            <div
                style={{
                    margin: '0 auto',
                    maxWidth: 960,
                    padding: '1rem 1.5rem',
                }}
            >
                <Header meta={siteMetadata} title={title} />

                {children}
            </div>
            <Footer width={960} />
        </Fragment>
    );
};

export const Layout = ({ children, title }) => (
    <StaticQuery
        query={query}
        render={data => (
            <PageLayout data={data} title={title}>
                {children}
            </PageLayout>
        )}
    />
);
