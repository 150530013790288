import React from 'react';
import { palette } from './palette';
import styled from 'styled-components';
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants/src';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    ${MOBILE_MEDIA_QUERY} {
        flex-direction: column;
    }
`;

const ColorInfo = styled.div`
    border-radius: 4px;
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 2px 2px 3px ${palette.lightGray};
`;

const Color = styled.div`
    border: 1px solid #ccc;
    background-color: ${props => props.color};
    width: 64px;
    height: 64px;
    margin-bottom: 0.5rem;
`;

export const PaletteDetails = () => {
    return (
        <Container>
            {Object.keys(palette).map(key => (
                <ColorInfo key={key}>
                    <Color color={palette[key]} />
                    <small>{key}</small>
                </ColorInfo>
            ))}
        </Container>
    );
};
