import styled from 'styled-components';
import { palette } from '../theme/palette';
import typography from '../theme/typography';

export const Badge = styled.span.attrs({
    outline: props => props.outline !== undefined,
})`
    display: inline-block;
    padding: 3px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-family: ${typography.options.headerFontFamily.join(',')};
    ${typography.adjustFontSizeTo('16px')};
    border-radius: 3px;
    background-color: ${props => (props.outline ? 'none' : palette.secondary)};
    border: ${props => (props.outline ? `2px solid ${palette.secondary}` : 'none')};
    color: ${props => (props.outline ? palette.secondary : palette.bright)};
`;
