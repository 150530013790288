module.exports = {
    title: 'Pixel-in-Gene',
    siteUrl: 'https://blog.pixelingene.com',
    description: 'Exploring Frontend Development with Design / Graphics / Technology',
    keywords: [
        'front-end',
        'ui',
        'graphics',
        'react',
        'angular',
        'web',
        'html',
        'css',
        'javascript',
        'apple',
        'swift',
        'mobile',
        'desktop',
        'iOS',
    ],
    author: {
        name: 'Pavan Podila',
        bio: `
I have been building Frontend applications since 2001 and in this time, 
I've used a variety of \`UI\` technologies and tools on _Windows_, _Mac_ and _Linux_. 
For the past decade or so, I've been focusing exclusively on _Financial Applications_ 
for the \`Web\` and \`Mobile\` platforms. 

I am also a [Google Developer Expert (GDE)](https://developers.google.com/experts/people/pavan-podila) for Web Technologies. 
A Speaker, Author, Mentor.
`,
        twitter: {
            handle: 'pavanpodila',
            url: 'https://twitter.com/pavanpodila',
        },
        github: {
            handle: 'pavanpodila',
            url: 'https://github.com/pavanpodila',
        },
        email: 'pavan@pixelingene.com',
        linkedIn: 'https://linkedin.com/in/pavanpodila',
    },
};
