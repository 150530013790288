import React from 'react';
import RehypeReact from 'rehype-react';
import { Badge } from './badge';
import { Aside } from './aside';
import { JSFiddle } from './jsfiddle';
import { Silverlight } from './silverlight';
import { DemoFrame } from './demo-frame';
import { PaletteDetails } from '../theme/palette-details';
import { TypographyDetails } from '../theme/typography-details';
import { Logo } from './components';

const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
        badge: Badge,
        aside: Aside,
        jsfiddle: JSFiddle,
        silverlight: Silverlight,
        palette: PaletteDetails,
        'typography-details': TypographyDetails,
        logo: Logo,
        'demo-frame': DemoFrame,
    },
}).Compiler;

export const MarkdownBlock = ({ htmlAst }) => renderAst(htmlAst);
