import styled from 'styled-components';
import { palette } from '../theme/palette';
import logo from '../../static/icon-64x64.png';

export const PublishDate = styled.small`
    color: ${palette.secondary};
`;

export const Stack = styled.div.attrs({
    type: props => props.type || 'row',
    align: props => props.align || 'center',
    justify: props => props.justify || 'stretch',
    gap: props => (props.gap === undefined || props.gap === null ? '0.5rem' : props.gap),
})`
    text-align: left;
    display: flex;
    flex-direction: ${props => props.type};
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};

    & > * {
        margin-right: ${props => (props.type === 'row' ? props.gap : 0)};
        margin-bottom: ${props => (props.type === 'row' ? 0 : props.gap)};
    }
`;

export const Logo = styled.img.attrs({
    src: logo,
    alt: 'Site Logo',
})`
    width: 64px;
    transition: transform 0.3s ease-in-out;
    margin: 0;

    &:hover {
        transform: scale(1.25);
    }
`;
