import React from 'react';
import typography from '../../theme/typography';
import styled from 'styled-components';
import { formatReadTime } from '../../utils/time-to-read';
import { DiscussionEmbed } from 'disqus-react';
import { LeftArrow, NavEnd, NavLink, NavLinkContainer, RightArrow } from '../nav-link';
import { MarkdownBlock } from '../markdown-block';
import avatar from '../../pages/about/pavan.jpg';
import meta from '../../theme/meta';
import { palette } from '../../theme/palette';
import { SocialIcon } from 'react-social-icons';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MetaBar = styled(Row)`
    margin-bottom: ${typography.rhythm(0.25)};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const PostAvatar = styled.a`
    display: inline-block;
    margin-right: 0.5rem;
    transition: transform 0.3s ease-out;

    &:hover {
        transform: translateY(-0.25rem);
    }

    & img {
        height: 40px;
        width: 40px;
        margin: 0;
        border-radius: 20px;
        border: 2px solid ${palette.lightGray};
        box-shadow: 0 0 4px ${palette.dark};
    }
`;

const PostFooter = styled.footer`
    border-top: 1px dashed ${palette.lightGray};
    border-bottom: 1px dashed ${palette.lightGray};
    padding: 0.25rem 0;
    margin: 3rem 0;
    font-size: 0.75rem;

    & h6 {
        font-weight: normal;
    }
`;

export const Post = ({ data, context: { previous, next } }) => {
    const {
        markdownRemark: { id, timeToRead, frontmatter, htmlAst },
    } = data;

    return (
        <div>
            <header>
                <MetaBar>
                    <PostAvatar href={'/about'}>
                        <img src={avatar} alt={meta.author.name} />
                    </PostAvatar>
                    <Column>
                        <strong>{frontmatter.date}</strong>
                        <small>{formatReadTime(timeToRead)}</small>
                    </Column>
                </MetaBar>

                <h1 style={{ marginBottom: '4rem' }}>{frontmatter.title}</h1>
            </header>

            <MarkdownBlock htmlAst={htmlAst} />

            <PostFooter>
                <Column>
                    <h6>{frontmatter.title}</h6>
                    <Row>
                        <PostAvatar href={'/about'}>
                            <img src={avatar} alt={meta.author.name} />
                        </PostAvatar>
                        <Column>
                            <div>
                                <strong>{meta.author.name}</strong>
                                <SocialIcon
                                    url={meta.author.twitter.url}
                                    style={{ width: 24, height: 24, marginLeft: '0.25rem' }}
                                />
                            </div>
                            <small>{frontmatter.date}</small>
                        </Column>
                    </Row>
                </Column>
            </PostFooter>

            <NavLinkContainer>
                {previous ? (
                    <NavLink to={previous.fields.path} align={'left'}>
                        <LeftArrow />
                        <span>{previous.frontmatter.title}</span>
                    </NavLink>
                ) : (
                    <NavEnd align={'left'}>At the latest post</NavEnd>
                )}

                {next ? (
                    <NavLink to={next.fields.path} align={'right'}>
                        <span>{next.frontmatter.title}</span>
                        <RightArrow />
                    </NavLink>
                ) : (
                    <NavEnd align={'right'}>At the first post</NavEnd>
                )}
            </NavLinkContainer>

            <DiscussionEmbed
                shortname={'pixelingene'}
                config={{
                    id,
                    title: frontmatter.title,
                }}
            />
        </div>
    );
};
