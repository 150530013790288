import { createGlobalStyle } from 'styled-components';
import { palette } from './palette';
import typography, { codeFontFamily } from './typography';
import React from 'react';

const containerHorizontalPadding = 0.5;
const lineNumberLeftPadding = 2.5;

export const GlobalStyle = createGlobalStyle`
    code, code[class*="language-"], pre[class*="language-"] {
        font-family: ${codeFontFamily.join(',')};
        border-radius: 0.3em;
  }
    
    :not(pre) > code, :not(pre) > code[class*="language-"] {
        font-size: 0.8em;
        padding: 0.1rem ${containerHorizontalPadding}rem;
        text-shadow: none;
        background-color: ${palette.lightGray};
        color: ${palette.secondary};
    }

    .gatsby-highlight {
        margin: 0;
        margin-bottom: 1.75rem;
        border-radius: 0.3em;
        overflow: auto;
        background-color: ${palette.darkGray};
        padding: ${containerHorizontalPadding}rem; 
  }
    
    .gatsby-highlight-code-line {
        background-color: ${palette.secondary};
        display: block;
        margin: 0 -${containerHorizontalPadding + lineNumberLeftPadding}rem;
        padding: 0 ${containerHorizontalPadding + lineNumberLeftPadding}rem;
}

    .gatsby-highlight pre[class*="language-"] {
        margin: 0;
        padding: 0;
        overflow: initial;
        float: left;
        min-width: 100%;

        background-color: ${palette.darkGray};
    }
    
    .gatsby-highlight pre[class*="language-"].line-numbers {
        padding-left: ${lineNumberLeftPadding}rem;
    }
      
    pre[class*="language-"].line-numbers {
      padding-top: 0;
      padding-bottom: 0;
    }

    .gatsby-remark-toc {
        ${typography.adjustFontSizeTo('1rem')};
        background-color: ${palette.lightGray};
        padding: ${typography.rhythm(1)}
        
    }

    .gatsby-remark-toc a {
        color: ${palette.secondary};
    }
    
`;
