import React from 'react';
import Helmet from 'react-helmet';

import siteMeta from '../theme/meta';

export const TwitterCardHelmet = ({ description, title, image }) => {
    return (
        <Helmet>
            <meta name={'twitter:card'} content={'summary'} />
            <meta name={'twitter:site'} content={`@${siteMeta.author.twitter.handle}`} />
            {title ? <meta name={'twitter:title'} content={title} /> : null}
            {description ? <meta name={'twitter:description'} content={description} /> : null}
            {image ? <meta name={'twitter:image'} content={image} /> : null}
        </Helmet>
    );
};
